import { Cell } from '../../components/table/types';
import { formatRisks, formatedRiskBoxes } from '../../utils/constants/envAndrisks';
import { isMoreThanNDays } from '../../utils/functions/date';

import { ProjectData, TransformedData } from './types';

export const columns = [
  [
    {
      key: 'project_name',
      name: 'Project name',
      isPinned: true,
      isSortable: true,
      cellStyle: {
        width: '256px',
        position: 'sticky',
        left: 0,
        zIndex: 11,
        borderRight: '2px solid var(--TableCell-borderColor)',
        fontSize: 'sm',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
    {
      key: 'project_id',
      name: 'Project ID',
      isSortable: true,
      cellStyle: {
        width: '104px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      key: 'duration',
      name: 'Duration/vintage',
      isSortable: true,
      cellStyle: {
        width: '144px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      key: 'ghg_rating',
      name: 'GHG rating',
      isSortable: true,
      cellStyle: {
        width: '132px',
      },
      contentStyle: {
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
    {
      key: 'sdg_impact_rating',
      name: 'SDG rating',
      isSortable: true,
      cellStyle: {
        width: '132px',
      },
      contentStyle: {
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
    {
      key: 'esr',
      name: 'Env. & social risks',
      isSortable: true,
      tooltip:
        'Projects screened for environmental and social risks (ESRs) are rated in 10 safeguard areas. Each safeguard area is assigned a risk level: no risk identified, low risk, moderate (mod) risk, high risk, or critical (crit) risk.',
      cellStyle: {
        width: '186px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
    {
      key: 'project_category',
      name: 'Project category',
      isSortable: true,
      cellStyle: {
        width: '173px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
        lineHeight: '20px',
      },
    },
    {
      key: 'project_type',
      name: 'Project type',
      isSortable: true,
      cellStyle: {
        width: '224px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
    {
      key: 'region',
      name: 'Region',
      isSortable: true,
      cellStyle: {
        width: '112px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
        lineHeight: '20px',
      },
    },
    {
      key: 'country',
      name: 'Country',
      isSortable: true,
      cellStyle: {
        width: '112px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
    {
      key: 'methodology',
      name: 'Methodology',
      isSortable: true,
      cellStyle: {
        width: '240px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
    {
      key: 'project_attribute',
      name: 'Project attributes',
      isSortable: true,
      cellStyle: {
        width: '298px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
    {
      key: 'confirmed_sdg',
      name: 'Confirmed SDGs',
      isSortable: true,
      cellStyle: {
        width: '164px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
    {
      key: 'sdg_certificate',
      name: 'SDG certificates',
      isSortable: true,
      cellStyle: {
        width: '164px',
      },
      contentStyle: {
        textAlign: 'start',
        fontWeight: 400,
        fontSize: 'sm',
        ml: 0,
      },
    },
  ],
] as Cell[][];

export const cellRenderOrder = [
  'project_name',
  'project_id',
  'duration',
  'ghg_rating',
  'sdg_impact_rating',
  'esr',
  'project_category',
  'project_type',
  'region',
  'country',
  'methodology',
  'project_attribute',
  'confirmed_sdg',
  'sdg_certificate',
];

export const transformData = (data: ProjectData[]): TransformedData[] => {
  return data.map((item) => {
    return {
      _id: item?.registry + item?.project_id + item?.duration,
      project_name: item?.project_name,
      project_id: item?.registry + item?.project_id,
      standard: item?.registry,
      id: item?.project_id,
      is_new: item?.first_live_date
        ? !isMoreThanNDays(item?.first_live_date, new Date().toDateString(), 30)
        : false,
      crediting_period_id: item?.crediting_period_id,
      duration: item?.duration,
      ghg_rating: item?.ghg_rating,
      ghg_rating_value: item?.ghg_rating_value,
      sdg_impact_rating: item?.sdg_impact_rating,
      sdg_impact_rating_value: item?.sdg_impact_rating_value,
      esr: {
        risks: formatedRiskBoxes(item?.esr?.esrArea),
        description: formatRisks(item.esr?.esrArea),
      },
      project_category: item?.project_category?.display_name,
      project_type: {
        name: item?.project_type?.display_name,
        logo: item?.project_type?.logo,
      },
      country: item?.country?.display_name,
      region: item?.region?.display_name,
      methodology: item?.methodology?.display_name,
      project_attribute: (item?.project_attribute || [])?.map(
        (attribute) => attribute.display_name
      ),
      confirmed_sdg: (item?.confirmed_sdg || [])?.map((sdg) => sdg.sdg).join(', ') || 'None',
      sdg_certificate: item?.sdg_certificate,
    };
  });
};

export const sdgRatingsArray = ['+5', '+4', '+3', '+2', '+1', 'N/A', 'No Cert'];
export const ghgRatingsArray = ['N/A', 'AAA', 'AA', 'A', 'BBB', 'BB', 'B', 'C', 'D', 'E'];

export const getSDGRatingNumber = (rating: string) => {
  if (rating === 'No cert') {
    return 0;
  }
  if (rating === 'N/A') {
    return -1;
  }
  return Number(rating?.slice(1));
};

export const getProjectGroupDetails = (data) => {
  if (!data) return { groups: [], details: {} };
  const details = {};
  data.map(({ projectGroup, projectGroupName }) => {
    if (projectGroupName in details) return;
    details[projectGroupName] = projectGroup;
  });
  const orderedDetails = Object.keys(details)
    .sort()
    .reduce((obj, key) => {
      obj[key] = details[key];
      return obj;
    }, {});
  return {
    groups: Object.keys(orderedDetails),
    details,
  };
};

export const getGHGRatingPercentage = (rating: number) => {
  if (rating >= 1 && rating <= 10) {
    const gradeIndex = rating - 1;
    return 100 - 10 * gradeIndex;
  }
  return 0;
};

export const getSDGRatingPercentage = (rating: number, sdg_certificate?: string) => {
  const notDisplayCertificates = ['ACR', 'CAR', 'GOLD', 'GS', 'VCS', 'none', 'None'];

  if (sdg_certificate !== undefined && !notDisplayCertificates.includes(sdg_certificate)) {
    if (rating === 0 && sdg_certificate !== null) {
      return 0; // For cases where the rating is 0 but certificate exists.
    }
  }

  if (rating >= 1 && rating <= 5) {
    return (rating / 5) * 100;
  }

  return 0;
};

export const transformCachedData = (data) => {
  if (data.length === 0) return undefined;
  return data.map((item) => transformData(item.projects.data.flat()));
};
