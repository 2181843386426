import { useMemo, useState } from 'react';

import {
  Button,
  ButtonGroup,
  Card,
  Skeleton,
  Stack,
  Typography,
  useColorScheme,
  useTheme,
} from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import ReactEcharts from 'echarts-for-react';
import { useParams } from 'react-router-dom';

import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { retireeProjectPortfolio } from '../../../services/axios/endpoints';
import { median } from '../../../utils/functions/number';
import { formatNumberWithCommas } from '../../../utils/functions/number';
import { useFetchUserPermissions } from '../../../utils/hooks/useFetchUserPermission';

interface ChartOption {
  key: 'total_volume' | 'total_project';
  label: string;
}

const chartTypes: Array<ChartOption> = [
  {
    key: 'total_project',
    label: 'Projects',
  },
  {
    key: 'total_volume',
    label: 'Credits',
  },
];

export default function ProjectPortfolio({
  scrollRetireeTableIntoView,
}: {
  scrollRetireeTableIntoView: ({ ghg_rating, sdg_rating }) => void;
}) {
  const { hasPermissionForRetirementInsights } = useFetchUserPermissions();
  const theme = useTheme();
  const { retiree } = useParams();
  const { mode } = useColorScheme();
  const [selectedChartType, setSelectedChartType] = useState<ChartOption>(chartTypes[1]);

  const { data, isLoading } = useQuery({
    queryKey: ['retiree-project-portfolio-data', retiree],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(retireeProjectPortfolio, {
        params: {
          retiree_name: retiree,
        },
      }),
    select: (data) => data?.data?.data,
    enabled: hasPermissionForRetirementInsights,
  });

  const handleSelectChartType = (rating: ChartOption) => {
    setSelectedChartType(rating);
  };

  const scatterData = useMemo(() => {
    const result = data;
    const datas: any[] = [];
    const ghgMedian: number[] = [];
    const sdgMedian: number[] = [];

    if ((result || []).length > 0) {
      (result || []).forEach((hr_row, i) => {
        const sGHG = hr_row['ghg_risk_rating'] ? 9 - hr_row['ghg_risk_rating'] : 0;
        const sSDG = hr_row['sdg_impact_rating'] || 0;
        const ghgRatingGrade = hr_row['ghg_risk_rating_grade'] ?? '';
        const sdgRatingGrade = hr_row['sdg_impact_rating_grade'] ?? '';
        if (sGHG <= 10) {
          ghgMedian.push(sGHG - 1);
          sdgMedian.push(sSDG);
          ghgMedian.sort();
          sdgMedian.sort();
          const gMedain = median(ghgMedian);
          const sMedain = median(sdgMedian);
          const cordinates = [sGHG - 0.5, parseFloat(sSDG) + 0.5];
          const cntRating = hr_row[selectedChartType.key] || 0;

          const bubData: any = [];
          for (let z = 0; z < sGHG - 1; z++) {
            bubData.push(null);
          }
          bubData.push(sSDG);
          const color = '#00A0B2';
          let drawline: any = {};
          let markline: any = {};
          let drawArea: any = {};
          let markArea: any = {};
          if (sSDG <= 0) {
            markline = drawline = {
              data: [
                {
                  xAxis: 0,
                },
                {
                  yAxis: 0,
                },
              ],
              symbol: 'none',
              silent: 'true',
              label: {
                show: false,
              },
              lineStyle: {
                color: '#666666',
                type: 'dashed',
                width: '1',
              },
            };
            markArea = drawArea = {
              itemStyle: {
                color: mode === 'light' ? 'rgba(99,107,116,0.01)' : 'rgba(145, 149, 153, 0.01)',
              },
              data: [
                [
                  {
                    tooltip: {
                      show: false,
                    },
                    xAxis: 0,
                    yAxis: 0,
                  },
                  {
                    xAxis: 10,
                    yAxis: 1,
                  },
                ],
              ],
            };
          }
          if ((result || [])?.length === i + 1) {
            markline = drawline = {
              data: [
                {
                  xAxis: gMedain,
                },
                {
                  yAxis: sMedain,
                },
              ],
              symbol: 'none',
              silent: 'true',
              label: {
                show: false,
              },
            };
            markArea = drawArea = {
              itemStyle: {
                color: '#4661EC',
                opacity: 0.1,
              },
              label: {
                show: false,
              },
              data: [
                [
                  {
                    name: 'Top quartile',
                    xAxis: 10,
                    yAxis: 6,
                  },
                  {
                    xAxis: gMedain,
                    yAxis: sMedain,
                  },
                ],
              ],
            };
          }

          let size = 0;
          if (selectedChartType?.key === 'total_volume') {
            if (cntRating < 10000) {
              size = 5;
            } else if (cntRating < 100000) {
              size = 8;
            } else if (cntRating < 1000000) {
              size = cntRating / 100000 + 10;
            } else if (cntRating < 10000000) {
              size = cntRating / 1000000 + 20;
            } else {
              size = cntRating / 10000000 + 40;
            }
          } else {
            if (cntRating < 5) {
              size = cntRating * 5;
            } else {
              size = cntRating * 7 > 45 ? 45 : cntRating * 7;
            }
          }

          const d = {
            symbolSize: size,
            name:
              selectedChartType?.key === 'total_volume'
                ? 'Credits Retired (tCO2e)'
                : 'Number of Projects Retired',
            symbolOffset: [0, 0],
            color: color,
            data: [
              {
                value: cordinates,
                name: `${formatNumberWithCommas(Number(cntRating))} ${selectedChartType?.key === 'total_volume' ? 'tCO2' : 'projects'}`,
                sdgRatingGrade,
                ghgRatingGrade,
              },
            ],
            type: 'scatter',
            markLine: { ...markline, 0: { ...drawline } },
            markArea: { ...markArea, 0: { ...drawArea } },
          };
          datas.push(d);
        }
      });
    }
    return datas;
  }, [data, mode, selectedChartType]);

  const option = useMemo(() => {
    const x = [0, 1, 2, 3, 4, 5, 6, 7];
    const y = [0, 1, 2, 3, 4, 5, 6];
    if (data) {
      return {
        backgroundColor: 'transparent',
        legend: {
          display: 'none',
          selectedMode: false,
        },
        tooltip: {
          position: 'top',
          trigger: 'item',
          formatter: function (params) {
            return params.name;
          },
        },
        grid: {
          containLabel: false,
        },
        xAxis: {
          name: 'GHG rating',
          nameLocation: 'middle',
          nameGap: 30,
          nameTextStyle: {
            color: theme.palette.text.primary,
          },
          type: 'value',
          data: x,
          min: 0,
          max: 8,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#B3B3B3',
              type: 'dashed',
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#B3B3B3',
              type: 'dashed',
              opacity: 0.2,
            },
          },
          axisTick: {
            show: false,
          },
          label: {
            color: theme.palette.text.primary,
          },
          axisLabel: {
            show: true,
            interval: 0,
            overflow: 'breakAll',
            position: 'bottom',
            fontSize: 11,
            fontWeight: 500,
            fontFamily: 'lato',
            hideOverlap: true,
            align: 'left',
            padding: [0, 0, 0, 0],
            color: theme.palette.text.primary,
            formatter: function (_, index) {
              if (index === 0) {
                return 'Low\nintegrity';
              } else if (index === 4) {
                return 'High\nintegrity';
              }
              return '';
            },
            textStyle: {
              fontFamily: "'Inter Variable', sans-serif",
            },
          },
        },
        yAxis: [
          {
            name: 'SDG rating',
            nameLocation: 'middle',
            nameGap: 30,
            nameTextStyle: {
              color: theme.palette.text.primary,
            },
            type: 'value',
            data: y,
            min: 0,
            max: 6,
            splitLine: {
              show: false,
              lineStyle: {
                color: ['#aaa'],
                type: 'dashed',
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#B3B3B3',
                type: 'dashed',
                opacity: 0.2,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              name: 'Y-Axis',
              overflow: 'breakAll',
              interval: 0,
              position: 'top',
              rotate: 90,
              fontSize: 11,
              fontWeight: 500,
              fontFamily: 'lato',
              hideOverlap: true,
              align: 'left',
              color: theme.palette.text.primary,
              formatter: function (_, index) {
                if (index === 0) {
                  return 'None ';
                } else if (index === 2) {
                  return 'Low impact';
                } else if (index === 5) {
                  return 'High impact';
                }
                return '';
              },
              textStyle: {
                fontFamily: "'Inter Variable', sans-serif",
              },
            },
          },
        ],
        series: scatterData,
      };
    }
    return {};
  }, [mode, scatterData, theme]);

  const onChartClick = (params) => {
    if (params.componentType !== 'series') return;
    scrollRetireeTableIntoView({
      ghg_rating: [params?.data?.ghgRatingGrade],
      sdg_rating:
        params?.data?.sdgRatingGrade === 'No cert'
          ? [params?.data?.sdgRatingGrade, 'N/A']
          : [params?.data?.sdgRatingGrade],
    });
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <Card>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Stack gap={3} direction={'row'} alignItems={'center'}>
          <Typography fontWeight={'xl'} fontSize={'xl'} color='primary'>
            Project Portfolio
          </Typography>
        </Stack>
        <ButtonGroup color='primary'>
          {chartTypes.map((rating) => {
            const { key, label } = rating;
            const isSelected = selectedChartType.key === key;
            return (
              <Button
                key={key}
                variant={isSelected ? 'solid' : 'outlined'}
                sx={{ width: 'max-content' }}
                onClick={() => handleSelectChartType(rating)}
              >
                {label}
              </Button>
            );
          })}
        </ButtonGroup>
      </Stack>
      {isLoading ? (
        <Stack height={'400px'} alignItems={'center'} flexDirection={'column'}>
          <Skeleton height={400} width={'95%'} />
        </Stack>
      ) : (
        <ReactEcharts
          option={option}
          style={{ height: '400px' }}
          theme={mode}
          onEvents={onEvents}
        />
      )}

      <Typography color='primary' fontSize={'sm'} fontWeight={500}>
        Does not include {selectedChartType.key === 'total_project' ? '' : 'credits from'} non-rated
        projects.
      </Typography>
    </Card>
  );
}
